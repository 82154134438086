import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory, Link } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { useInView } from 'react-intersection-observer';
import { useFlags } from 'launchdarkly-react-client-sdk';
import cn from 'classnames';
import { Notification, Button, CustomIcon } from '@components/shared';
import { ExternalListing, ExternalListingAnalyticsInformation, StoreState } from '@root/types';
import routes from '@root/routes';
import useToggle from '@shared/useToggle';
import { SubmitButton } from '@components/shared/forms';
import actions from '@store/actions/tourbookExternalListingPage';
import { getExternalListing } from '@store/selectors';
import useAnalytics from '@root/shared/useAnalytics';
import { Form, Layout, Drawer, LocationFields, ListingFields, MediaFields } from '..';
import ErrorMessage from './ErrorMessage';
import styles from '../TourbookExternalListing.module.less';
import submitTourbookExternalListing from '../helpers';

const mapState = (state: StoreState, ownProps) => ({
  externalListing: getExternalListing(state, ownProps.match.params.externalListingId),
});

const mapDispatch = {
  editExternalListingPageLoad: ({
    tourbookId,
    externalListingId,
  }: {
    tourbookId: string;
    externalListingId: string;
  }) => actions.editExternalListingPageLoad({ tourbookId, externalListingId }),
  receiveExternalListing: (l: ExternalListing) => actions.receiveExternalListing(l),
};

const connector = connect(mapState, mapDispatch);
type ReduxProps = ConnectedProps<typeof connector>;

export function EditPage({
  editExternalListingPageLoad,
  externalListing,
  receiveExternalListing,
}: ReduxProps) {
  const { externalListingId, tourbookId } = useParams<{
    externalListingId: string;
    tourbookId: string;
  }>();

  const { t } = useTranslation('tourbook');
  const flags = useFlags();

  const { tourbookInteraction, PARAMETERS } = useAnalytics();
  const history = useHistory();

  useEffect(() => {
    editExternalListingPageLoad({ tourbookId, externalListingId });
    // FIXME: Either add the exhaustive deps or delete this line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tourbookId, externalListingId]);

  const goToTourbook = () => {
    history.push(routes.tourbook(tourbookId));
  };

  const { value: errorsAreShown, setTrue: showErrors } = useToggle(false);

  const { value: isPromptEnabled, setFalse: disablePrompt } = useToggle(true);

  const onSubmitSuccess = async response => {
    await disablePrompt();
    goToTourbook();

    const {
      listing,
      analyticsInformation,
    }: {
      listing: ExternalListing;
      analyticsInformation: ExternalListingAnalyticsInformation;
    } = await response.json();
    receiveExternalListing(listing);

    tourbookInteraction({
      action: PARAMETERS.saveChanges,
      actionType: 'SAVE_CHANGES_FOR_TOURBOOK_EXTERNAL_LISTING',
      sourcePage: PARAMETERS.tourbookExternalListingPage,
      otherAttributes: analyticsInformation,
    });

    Notification.info({
      title: t('externalListing.updated'),
    });
  };

  const onSubmit = values =>
    submitTourbookExternalListing({
      values,
      tourbookId,
      externalListingId,
      onSuccess: onSubmitSuccess,
    });

  const formFieldsRef = useRef<HTMLDivElement | null>(null);

  const threshold = [0, 0.5, 1];
  const [locationRef, _locationInView, locationObserver] = useInView({ threshold: [...threshold] });
  const [mediaRef, _mediaInView, mediaObserver] = useInView({ threshold: [...threshold] });
  const [listingRef, _listingInView, listingObserver] = useInView({ threshold: [...threshold] });

  const locationRatio = locationObserver?.intersectionRatio || 0;
  const mediaRatio = mediaObserver?.intersectionRatio || 0;
  const listingRatio = listingObserver?.intersectionRatio || 0;

  const showLocationUnderline = locationRatio >= Math.max(mediaRatio, listingRatio);
  const showMediaUnderline = !showLocationUnderline && mediaRatio >= listingRatio;
  const showListingUnderline = !showLocationUnderline && !showMediaUnderline;

  const maxAskingRent = flags['external-listing-asking-rent-range']
    ? externalListing?.maxAskingRentMagnitude || undefined
    : undefined;

  return externalListing ? (
    <Form
      onSubmit={onSubmit}
      isPromptEnabled={isPromptEnabled}
      tourbookId={tourbookId}
      initialValues={{
        ...externalListing,
        size: externalListing.size.magnitude,
        minAskingRent: flags['external-listing-asking-rent-range']
          ? externalListing.minAskingRentMagnitude ?? externalListing.askingRentMagnitude
          : externalListing.askingRentMagnitude ?? undefined,
        maxAskingRent: maxAskingRent || undefined,
        askingRentUseRange: !!maxAskingRent,
      }}
      countryCode={externalListing.countryCode}
    >
      <Layout goToTourbook={goToTourbook}>
        <Drawer
          header={
            <>
              <div>
                <Link className={styles.sidebarLink} to={routes.tourbook(tourbookId)}>
                  <CustomIcon type="chevron-left" />
                </Link>
                <span className={styles.sidebarTitle}>
                  {t('externalListing.updateSidebarTitle')}
                </span>
              </div>
              <ul className={styles.tabs}>
                <li className={cn(styles.tab, showLocationUnderline && styles.active)}>
                  <button
                    type="button"
                    onClick={() => {
                      document.getElementById('location-fields')!.scrollIntoView();
                    }}
                  >
                    {t('externalListing.location')}
                  </button>
                </li>
                <li className={cn(styles.tab, showMediaUnderline && styles.active)}>
                  <button
                    type="button"
                    onClick={() => {
                      document.getElementById('media-fields')!.scrollIntoView();
                    }}
                  >
                    {t('externalListing.media.heading')}
                  </button>
                </li>
                <li className={cn(styles.tab, showListingUnderline && styles.active)}>
                  <button
                    type="button"
                    onClick={() => {
                      document.getElementById('listing-fields')!.scrollIntoView();
                    }}
                  >
                    {t('externalListing.listing')}
                  </button>
                </li>
              </ul>
            </>
          }
          footer={
            <>
              <Button type="secondary" onClick={goToTourbook}>
                {t('common:cancel')}
              </Button>
              <SubmitButton
                onClick={() => {
                  formFieldsRef.current!.scrollTop = 0;
                  showErrors();
                }}
              >
                {t('common:saveChanges')}
              </SubmitButton>
            </>
          }
        >
          <div className={styles.formFields} ref={formFieldsRef} id="form-fields">
            <ErrorMessage show={errorsAreShown} />
            <LocationFields
              listingPersisted
              containerRef={locationRef}
              isCountryFieldEnabled={false}
            />
            <MediaFields containerRef={mediaRef} />
            <ListingFields containerRef={listingRef} />
          </div>
        </Drawer>
      </Layout>
    </Form>
  ) : null;
}

export default connector(EditPage);
