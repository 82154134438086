import { Tag } from '@viewthespace/components';
import { CertificateStatus } from '@root/types';
import { useTranslation } from 'react-i18next';

const certificateStatusTranslationMapping = {
  complete: 'complete',
  pending: 'pending',
  awaiting_validation: 'awaitingValidation',
  missing_dns_records: 'missingDnsRecords',
  error: 'error',
};
const certificateStatusColorMapping: {
  [key: string]: 'success' | 'attention' | 'critical';
} = {
  complete: 'success',
  pending: 'attention',
  awaiting_validation: 'attention',
  missing_dns_records: 'critical',
  error: 'critical',
};

export type Props = {
  certificateStatus: CertificateStatus;
};
const CertificateStatusSection = ({ certificateStatus }: Props) => {
  const { t } = useTranslation('admin');

  const tagText = t(
    `microsite.certificateStatus.${certificateStatusTranslationMapping[certificateStatus]}`,
  );
  const tagColor = certificateStatusColorMapping[certificateStatus];

  return (
    <>
      <div className="flex flex-col gap-1">
        <span className="font-body-medium-emphasis text-black-100">
          {t('microsite.certificateStatus.title')}
        </span>
        <Tag text={tagText} color={tagColor} />
      </div>
    </>
  );
};

export default CertificateStatusSection;
