import { Tag } from '@viewthespace/components';
import { WwwRedirectStatus } from '@root/types';
import { useTranslation } from 'react-i18next';

const wwwRedirectTranslationMapping: Record<WwwRedirectStatus, string> = {
  certificate_pending: 'certificatePending',
  certificate_awaiting_validation: 'certificateAwaitingValidation',
  certificate_missing_dns_records: 'certificateMissingDnsRecords',
  certificate_error: 'certificateError',
  bucket_pending_creation: 'bucketPendingCreation',
  bucket_pending_configuration: 'bucketPendingConfiguration',
  cloudfront_distribution_pending: 'cloudfrontDistributionPending',
  complete: 'complete',
};

const wwwRedirectColorMapping: Record<WwwRedirectStatus, 'success' | 'attention' | 'critical'> = {
  certificate_pending: 'attention',
  certificate_awaiting_validation: 'attention',
  certificate_missing_dns_records: 'critical',
  certificate_error: 'critical',
  bucket_pending_creation: 'attention',
  bucket_pending_configuration: 'attention',
  cloudfront_distribution_pending: 'attention',
  complete: 'success',
};
export type Props = {
  status: WwwRedirectStatus;
};
const WwwRedirectStatusSection = ({ status }: Props) => {
  const { t } = useTranslation('admin');

  const tagText = t(`microsite.wwwRedirectStatus.${wwwRedirectTranslationMapping[status]}`);
  const tagColor = wwwRedirectColorMapping[status];

  return (
    <>
      <div className="flex flex-col gap-1">
        <span className="font-body-medium-emphasis text-black-100">
          {t('microsite.wwwRedirectStatus.title')}
        </span>
        <Tag text={tagText} color={tagColor} />
      </div>
    </>
  );
};

export default WwwRedirectStatusSection;
